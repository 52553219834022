





















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { IIndividualMember, IJuristicMember } from '@store/modules/about-project/Interfaces';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { IProject } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import debounce from '@/modules/debounce.js';

const NSAboutProject = namespace('storeAboutProject');
const NSProject = namespace('storeProject');

import '../scss/AboutProjectMembers.scss';

@Component({
    name: 'AboutProjectMembers',
    components: {
        BaseGroup: () => import('@components/BaseGroup/BaseGroup.vue'),
        MemberRow: () => import('../components/MemberRow.vue'),
        PopupEditResponsiblePerson: () => import('@components/popups/PopupEditResponsiblePerson.vue'),
        PopupEditJuristicPerson: () => import('@components/popups/PopupEditJuristicPerson.vue'),
    }
})

export default class AboutProjectMembers extends Vue {
    $refs!: {
        popupEditResponsiblePerson: HTMLFormElement,
        popupEditJuristicPerson: HTMLFormElement,
    }

    @Prop({}) projectId!: number | string;
    @Prop({}) individualMembersList!: any[];
    @Prop({}) juristicMembersList!: any[];
    @Prop({}) juristicMembersListDefault!: any[];

    @NSAboutProject.Getter('juristicMembers') juristicMembers!: IJuristicMember[];
    @NSAboutProject.Getter('filters') filters!: any[];
    @NSAboutProject.Getter('filtersApply') filtersApply!: boolean;
    @NSAboutProject.Action(AboutProjectActions.A_EDIT_RESPONSIBLE_PERSON)
        editResponsiblePerson!: (payload) => Promise<void>;
    @NSProject.Getter('projectData') projectData!: IProject;

    debounceDelay: number = 300;
    changedItems: IIndividualMember[] | IJuristicMember[] = [];
    changedItemsIds: string[] = [];
    activationItem = null;
    activationEvent = null;
    selectedEditItem = null;
    selectedEditJuristicItem = null;
    changedRow: any = {};
    individualFilters: any[] = [];
    juristicFilters: any[] = [];

    @Watch('individualMembersList.length')
    onIndividualMembersList() {
        if (this.filtersApply) {
            if (this.filters.filter(filter => filter.block !== 'roleJuristic').length) {
                this.individualFilters = this.filters.filter(filter => filter.block !== 'roleJuristic');
            }
        }
    }

    @Watch('juristicMembersList.length')
    onJuristicMembersList() {
        if (this.filtersApply) {
            if (this.filters.filter(filter => filter.block === 'roleJuristic' || filter.block === 'companies').length) {
                this.juristicFilters = this.filters.filter(filter => filter.block === 'roleJuristic' || filter.block === 'companies');
            }
        }
    }

    get debouncedActivation() {
        return debounce(this.activateToggle, this.debounceDelay);
    }

    closeAndReset() {
        this.$refs.popupEditResponsiblePerson.closeAndReset();
        this.$refs.popupEditJuristicPerson.closeAndReset();
    }

    onChangeDataInRow(type) {
        this.$emit(type === 'juristic' ? 'confirm-edit-juristic-person' : 'confirm-edit-responsible-person');
    }

    onActivate(item, event, debounceActive = true) {
        this.activationItem = item;
        this.activationEvent = this.changedItemsIds.includes(item.customKey) ? !event : event;
        if (debounceActive) {
            this.debouncedActivation();
        } else {
            this.activateToggle();
        }
    }

    activateToggle() {
        const newItem = JSON.parse(JSON.stringify(this.activationItem));
        if (this.changedItemsIds.indexOf(newItem.customKey) !== -1) {
            this.changedItems.splice(this.changedItems.indexOf(newItem.customKey), 1);
            this.changedItemsIds.splice(this.changedItemsIds.indexOf(newItem.customKey), 1);
        } else {
            this.changedItems.push(newItem);
            this.changedItemsIds.push(newItem.customKey);
        }
        if (this.activationEvent && this.changedItemsIds.indexOf(newItem.customKey) !== -1) {
            newItem.activationDate = new Date();
            newItem.deactivationDate = null;
        } else {
            newItem.deactivationDate = new Date();
            newItem.activationDate = null;
        }

        const formData = new FormData();
        formData.append('appId', this.projectId.toString());
        formData.append('id', newItem.id);
        formData.append('name', newItem.name);
        formData.append('entityId', newItem.juristicPersonId);
        formData.append('roleId', newItem.role.id);
        formData.append('active', this.activationEvent !== null ? this.activationEvent! : '');

        if (this.activationEvent === false) {
            formData.append('isMain', 'false');
        }

        this.editResponsiblePerson(formData);
        this.activationItem = null;
        this.activationEvent = null;
    }

    itemForRow(item) {
        if (this.changedRow
            && this.changedRow.customKey === item.customKey
            && item.juristicPersonId
            && item.juristicPersonId === this.changedRow.juristicPersonId) {
            return this.changedRow;
        }
        else if (this.changedItemsIds.includes(item.id)) {
            this.changedItems.forEach((changeItem) => {
                if (changeItem.id === item.id) {
                    return changeItem;
                }
            })
        }
        return item;
    }

    openEditPopup(item) {
        this.selectedEditItem = JSON.parse(JSON.stringify(item));
        this.$nextTick(() => {
            this.$refs.popupEditResponsiblePerson.openPopup();
        })
    }

    openEditJuristicPopup(item) {
        this.selectedEditJuristicItem = JSON.parse(JSON.stringify(item));
        this.$nextTick(() => {
            this.$refs.popupEditJuristicPerson.openPopup();
        })
    }
}
